import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {}

  handleBackendError(response: HttpErrorResponse): Observable<never> {
    this.loaderService.hide();
    let errorMessage = '';
    if (response.status === 0 || response.error.errors === null) {
      errorMessage = this.translateService.instant(
        'SHARED.TOAST_ERROR_SMT_WENT_WRONG'
      );
    } else if (typeof response.error.errors === 'string') {
      errorMessage = response.error.errors;
    } else {
      const responseErrorMessages = Object.values(response.error.errors);
      responseErrorMessages.forEach((message: string) => {
        errorMessage += message + '\n';
      });
    }
    this.toastr.error(errorMessage);
    return throwError(() => response);
  }
}
